<template>
  <el-dialog append-to-body title="管理私人邮箱" :visible.sync="visible">
    <div class="cont-main">
      <div class="cont-head">
        <label-span label="专属邮箱：" :span="mail.mailAddr" />
      </div>
      <el-table
        class="cont-body"
        :data="tableData"
        border
        stripe
        v-loading="tableLoading"
        max-height="500"
      >
        <el-table-column
          type="index"
          label="序号"
          header-align="center"
          width="60"
        ></el-table-column>

        <el-table-column
          prop="emailUserName"
          label="用户姓名"
          min-width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.editing"
              placeholder="请输入用户姓名"
              size="mini"
              v-model="scope.row.emailUserName"
            ></el-input>
            <span v-else>{{ scope.row.emailUserName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="mailAddr"
          label="邮箱地址"
          min-width="180"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.editing"
              placeholder="请输入邮箱地址"
              size="mini"
              v-model="scope.row.mailAddr"
            >
            </el-input>
            <span v-else>{{ scope.row.mailAddr }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          header-align="center"
          fixed="right"
          width="120px"
        >
          <template slot-scope="scope">
            <div class="cont-oprt">
              <el-button
                v-if="!scope.row.editing"
                type="text"
                @click="handleBindEdit(scope.row, scope.$index)"
              >
                编辑
              </el-button>
              <el-button
                v-if="!scope.row.editing"
                type="text"
                style="color: #f5222d"
                @click="handleBindDelete(scope.row, scope.$index)"
              >
                删除
              </el-button>
              <el-button
                v-if="scope.row.editing"
                type="text"
                @click="handleBindConfirm(scope.row, scope.$index)"
              >
                保存
              </el-button>
              <el-button
                v-if="scope.row.editing"
                type="text"
                @click="handleBindCancel(scope.row, scope.$index)"
              >
                取消
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="cont-plus">
        <el-button
          :disabled="mail.bizType == 'KP'"
          @click="handleBindAdd"
          style="width: 100%"
          type="primary"
          plain
          >添加私人邮箱</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  reqGetRemindList,
  reqCreateRemind,
  reqUpdateRemind,
  reqRemoveRemind,
} from "@/service/itr/mail-remind";

export default {
  name: "ItrEditRemind",
  data() {
    return {
      mail: {
        id: undefined,
        mailAddr: undefined,
      },
      visible: false,
      tableData: [],
      tableLoading: false,
      editData: undefined,
      editIndex: undefined,
    };
  },
  async created() {},
  methods: {
    // 弹窗-显示
    showDialog(mail) {
      this.visible = true;

      this.mail = mail;
      this.getRemindList();
    },
    // 提醒-查询
    async getRemindList() {
      this.tableLoading = true;
      const { success, data } = await reqGetRemindList(this.mail.id);
      if (success) {
        data.forEach((item) => (item.editing = false));
        this.tableData = data;
      }
      this.tableLoading = false;
    },
    // 绑定-保存
    async handleBindConfirm(remind) {
      const userName = remind.emailUserName;
      if (
        userName == undefined ||
        userName == null ||
        userName == "" ||
        !/[\S]{1,30}/.test(userName)
      ) {
        this.toast("用户姓名不能为空且长度为1~30个字符！", "error");
        return;
      }

      const mailAddr = remind.mailAddr;
      if (
        mailAddr == undefined ||
        mailAddr == null ||
        mailAddr == "" ||
        !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g.test(mailAddr)
      ) {
        this.toast("邮箱地址不能为空并且必须符合邮箱格式！", "error");
        return;
      }

      if (remind.id == null) {
        let { success } = await reqCreateRemind(remind);
        if (success) {
          this.toast("新增私人邮箱成功", "success");

          this.editData = undefined;
          this.editIndex = undefined;
          remind.editing = false;
          this.getRemindList();
        }
      } else {
        let { success } = await reqUpdateRemind(remind);
        if (success) {
          this.toast("更新私人邮箱成功", "success");

          this.editData = undefined;
          this.editIndex = undefined;
          remind.editing = false;
        }
      }
    },
    // 绑定-取消
    handleBindCancel(remind, index) {
      if (remind.id == null) {
        this.tableData.splice(index, 1);
      }
      // console.log("handleBindCancel::editData", this.editData);
      // remind = JSON.parse(this.editData);
      Object.assign(this.tableData[index], JSON.parse(this.editData));
      this.editData = undefined;
      this.editIndex = undefined;
      this.tableData[index].editing = false;
    },
    // 绑定-新增
    handleBindAdd() {
      if (this.tableData.filter((item) => item.editing).length > 0) {
        this.toast("请先取消或保存正在编辑的行！", "warning");
        return;
      }

      let remind = {
        id: null,
        mailId: this.mail.id,
        emailUserName: undefined,
        mailAddr: undefined,
        editing: true,
      };
      this.editIndex = this.tableData.push(remind);
      this.editData = JSON.stringify(remind);
    },
    // 绑定-编辑
    handleBindEdit(remind, index) {
      this.editIndex = index;
      this.tableData[index].editing = true;
      this.editData = JSON.stringify(remind);
      // console.log("handleBindEdit::editData", this.editData);
    },
    // 绑定-删除
    async handleBindDelete(remind, index) {
      this.$confirm("确定删除绑定的私人邮箱吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let { success } = await reqRemoveRemind(remind);
          if (success) {
            this.tableData.splice(index, 1);
            this.editData = undefined;
            this.editIndex = undefined;
            this.$message({
              type: "success",
              message: "已删除成功！",
            });
            this.getRemindList();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除！",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding-top: 0px !important;
}
.cont-main {
  padding: 0px 30px 50px 30px;

  .cont-head {
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    margin-bottom: 10px;

    ::v-deep label {
      font-weight: 400;
      font-size: 16px;
    }
    ::v-deep span {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .cont-body {
    border: 1px solid #dddddd;

    .el-input {
      width: 100%;
    }

    .cont-oprt {
      .el-button {
        padding: 0px;
      }
    }
  }

  .cont-plus {
    margin-top: 20px;
  }
}
// .content-func {
//   display: flex;
//   justify-content: flex-end;

//   .el-button {
//     padding: 0px 10px;
//     margin-bottom: 12px;
//     margin-left: 10px;
//   }
// }
// .select-content {
//   padding: 0;
//   .cus_form {
//     padding: 24px;
//     width: 100%;
//   }
// }
// .operate-button {
//   display: flex;
//   justify-content: center;

//   .el-button {
//     padding: 0;
//     margin-right: 10px;
//   }
// }
// .bindingAndApplyFor {
//   float: right;
//   margin-bottom: 20px;
// }
// .dialogTableVisible_class {
//   ::v-deep .el-input {
//     display: block;
//     width: 450px;
//   }
//   ::v-deep .el-select {
//     display: block;
//   }
//   .baseInput {
//     width: 380px;
//     display: inline-block;
//     position: relative;
//     // margin-left: -210px;
//     ::v-deep .el-input-group__append {
//       position: absolute;
//       right: 0;
//       top: 0;
//       width: 75px;
//       height: 30px;
//     }
//   }
//   .line {
//     border-top: 1px solid #e6e6e6;
//     margin-top: -25px;
//     margin-bottom: 30px;
//   }
//   .private_line {
//     border-top: 1px solid #e6e6e6;
//     margin-bottom: 30px;
//   }
//   .invoice-top {
//     margin: 26px 40px 40px 40px;
//   }
//   .private-e-mail-header {
//     text-align: left;
//     margin: 30px 0 20px 20px;
//     font-size: 18px;
//     color: #303133;
//     font-weight: 400;
//   }
//   ::v-deep .el-form-item__error {
//     font-size: 12px;
//     top: 30px;
//   }
// }
// .exclusive_mailbox_class {
//   ::v-deep .el-form-item__content {
//     text-align: left;
//   }
// }
// .bottom-table {
//   ::v-deep .el-input {
//     display: block;
//     width: 100%;
//   }
// }
// .suffix_redact {
//   ::v-deep .el-input-group__append {
//     position: absolute;
//     right: 0;
//     top: 0;
//     width: 100px;
//     height: 30px;
//   }
// }
</style>
