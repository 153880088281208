import { get, post } from "../index.js";

// 邮箱-新增
export const reqAddMail = (data) => {
  return post("/itr/touch/mail/add", data).then((res) => res);
};

// 邮箱-编辑
export const reqEditMail = (data) => {
  return post("/itr/touch/mail/edit", data).then((res) => res);
};

// 邮箱-分页
export const reqMailList = (params) => {
  return get("/itr/touch/mail/page", params).then((res) => res);
};

// 邮箱-域名
export const reqMailDomain = () => {
  return get("/itr/touch/mail/domain").then((res) => res);
};
