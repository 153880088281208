<template>
  <div>
    <div class="content-select">
      <div class="select-content">
        <el-form ref="form" :model="queryForm" inline>
          <el-form-item label="业务类型">
            <el-select
              v-model="queryForm.bizType"
              placeholder="请选择业务类型"
              clearable
              queryFormable
            >
              <el-option
                v-for="item in dictionary.bizType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="邮箱地址">
            <el-input
              maxlength="50"
              v-model="queryForm.mailAddr"
              placeholder="请输入邮箱地址"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="邮箱类型">
            <el-select
              v-model="queryForm.mailType"
              placeholder="请选择邮箱类型"
              clearable
              queryFormable
            >
              <el-option
                v-for="item in dictionary.mailType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="所属组织">
            <organization-select
              :is-add-all="false"
              :model="queryForm"
              allLabel="请选择所属组织"
              clearable
              filterable
            >
            </organization-select>
          </el-form-item>

          <el-form-item label="所属用户">
            <el-select
              v-model="queryForm.userId"
              placeholder="请选择所属用户"
              clearable
              filterable
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.nickName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="margin-left: 20px">
            <select-button
              @reset="handleReset"
              @select="handleQuery"
            ></select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div class="content-func">
        <el-button type="primary" @click="handleMakeMail"
          >申请专属邮箱</el-button
        >
      </div>

      <div class="content-table">
        <el-table
          :data="tableData"
          stripe
          border
          v-loading="tableLoading"
          :header-cell-style="handleHeaderCellStyle"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            label="序号"
            fixed="left"
            width="80"
            align="center"
            header-align="center"
          ></el-table-column>

          <el-table-column
            prop="mailAddr"
            label="邮箱地址"
            fixed="left"
            min-width="200"
            align="left"
            header-align="left"
          ></el-table-column>

          <el-table-column
            prop="bizType"
            width="150"
            align="center"
            header-align="center"
            label="业务类型"
          >
            <template slot-scope="scope">
              <span>{{
                getDictLabel(dictionary.bizType, scope.row.bizType)
              }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="mailType"
            width="150"
            align="center"
            header-align="center"
            label="邮箱类型"
          >
            <template slot-scope="scope">
              <span
                >{{ getDictLabel(dictionary.mailType, scope.row.mailType) }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            prop="orgName"
            label="所属组织"
            show-overflow-tooltip
            min-width="260"
          ></el-table-column>

          <el-table-column
            prop="userName"
            label="所属用户"
            min-width="120"
          ></el-table-column>

          <el-table-column
            prop="remindCount"
            label="绑定私人邮箱（个数）"
            width="160"
            align="right"
            header-align="right"
          >
          </el-table-column>
          <el-table-column
            prop="operate"
            label="操作"
            align="center"
            header-align="center"
            fixed="right"
            width="160"
          >
            <template slot-scope="scope">
              <el-button
                :disabled="scope.row.bizType == 'KP'"
                type="text"
                @click="handleEditRemind(scope.row)"
              >
                管理私人邮箱
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[15, 30, 50, 100]"
          :current-page="queryForm.page"
          :page-size="queryForm.size"
          layout="total, sizes, prev, pager, next"
          :total="dataTotal"
        >
        </el-pagination>
      </div>
    </div>

    <edit-mail
      ref="editMailRef"
      :dictionary="dictionary"
      :userList="userList"
      :domain="mailDomain"
      @success="handleEditMailSuccess"
    ></edit-mail>

    <edit-remind ref="editRemindRef" @success="handleEditRemindSuccess">
    </edit-remind>
  </div>
</template>

<script>
import SelectButton from "@/components/buttons/SelectButton";
import OrganizationSelect from "@/components/widgets/OrganizationSelect";
import { changeMethods, pagingMethods, tableStyle } from "@/util/mixins";

import { reqMailList, reqMailDomain } from "@/service/itr/mail";
import EditMail from "./EditMail.vue";
import { reqUserList } from "@/service/user";
import EditRemind from "./EditRemind.vue";

export default {
  name: "mailboxSettings",
  components: {
    OrganizationSelect,
    SelectButton,
    EditMail,
    EditRemind,
  },
  mixins: [pagingMethods, tableStyle, changeMethods],
  data() {
    return {
      queryForm: {
        orgId: undefined,
        bizType: undefined,
        mailAddr: undefined,
        mailType: undefined,
        page: 1,
        size: 15,
      },

      orgList: [],
      userList: [],
      dictionary: {
        mailType: [],
        bizType: [],
      },

      mailForm: {
        id: null,
        bizType: "SP",
        mailType: "ZZ",
        mailAddr: undefined,
        orgId: undefined,
        userId: undefined,
      },

      pageSizes: [15, 30, 50, 100],
      dataTotal: 0,
      tableData: [],
      tableLoading: false,

      mailDomain: undefined,
    };
  },

  async created() {
    this.organizationsList = this.$store.state.user.organizations;

    Promise.all([
      this.initDict(),
      this.getMailDomain(),
      this.getUserList(),
    ]).then(() => {
      this.getMailList();
    });
  },
  methods: {
    // 字典-查询
    async initDict() {
      const { success, data } = await this.batchGetDicts({
        types: ["itr_biz_type", "itr_mail_type"],
      });
      if (success) {
        this.dictionary.bizType = data["itr_biz_type"];
        this.dictionary.mailType = data["itr_mail_type"];
      }
    },
    // 域名-查询
    async getMailDomain() {
      const { success, data } = await reqMailDomain();
      if (success) {
        this.mailDomain = data;
      }
    },
    // 用户查询
    async getUserList() {
      const { success, data } = await reqUserList();
      if (success) {
        this.userList = data;
      }
    },
    // 查询-重置
    handleReset() {
      this.queryForm = {
        orgId: undefined,
        userId: undefined,
        bizType: undefined,
        mailAddr: undefined,
        mailType: undefined,
        page: 1,
        size: 15,
      };
    },
    // 列表-条数
    handleSizeChange(val) {
      this.queryForm.page = 1;
      this.queryForm.size = val;
      this.getMailList();
    },
    // 列表-翻页
    handleCurrentChange(val) {
      this.queryForm.page = val;
      this.getMailList();
    },
    // 列表-查询
    handleQuery() {
      this.queryForm.page = 1;
      this.getMailList();
    },
    // 邮箱-分页
    async getMailList() {
      this.tableLoading = true;
      const { success, data } = await reqMailList(this.queryForm);
      if (success) {
        this.dataTotal = data.total;
        this.tableData = data.records;
      }
      this.tableLoading = false;
    },

    // 申请-弹窗
    handleMakeMail() {
      this.$refs.editMailRef.showDialog(this.mailForm);
    },
    handleEditMailSuccess() {
      this.mailForm = {
        id: null,
        bizType: "SP",
        mailType: "ZZ",
        mailAddr: undefined,
        orgId: undefined,
        userId: undefined,
      };
      this.handleQuery();
    },

    // 私邮-管理
    handleEditRemind(mail) {
      this.$refs.editRemindRef.showDialog(mail);
    },
    handleEditRemindSuccess() {
      this.handleQuery();
    },
  },
};
</script>

<style scoped lang="scss">
.content-func {
  display: flex;
  justify-content: flex-end;

  .el-button {
    padding: 0px 10px;
    margin-bottom: 12px;
    margin-left: 10px;
  }
}
.select-content {
  padding-bottom: 10px;

  .el-form-item {
    margin-bottom: 14px;
  }
}
</style>
