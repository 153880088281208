import { get, post } from "../index.js";

// 提醒-创建
export const reqCreateRemind = (data) => {
  return post("/itr/touch/remind/create", data).then((res) => res);
};

// 提醒-删除
export const reqRemoveRemind = (data) => {
  return post("/itr/touch/remind/remove", data).then((res) => res);
};

// 提醒-更新
export const reqUpdateRemind = (data) => {
  return post("/itr/touch/remind/update", data).then((res) => res);
};

// 提醒-查询
export const reqGetRemindList = (mailId) => {
  return get(`/itr/touch/remind/list/${mailId}`).then((res) => res);
};
